














































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

const ArelavSectionProps = Vue.extend({
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    defaultImage: {
      type: String,
      required: true
    },
    sections: {
      type: Array,
      required: true
    }
  }
})

@Component({
  components: {
  }
})
export default class ArelavSection extends mixins(ArelavSectionProps, Vue) {}
