
















































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

const ArelavOfferCardProps = Vue.extend({
  props: {
    card: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      required: true
    }
  }
})

@Component
export default class ArelavOfferCard extends mixins(ArelavOfferCardProps, Vue) {}
