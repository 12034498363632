<template>
  <div id="app">
    <ArelavHeader />
    <main>
      <fade-transition
        origin="center"
        mode="out-in"
        :duration="250"
      >
        <router-view />
      </fade-transition>
    </main>
    <ArelavFooter />
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
import ArelavHeader from '@/views/arelav/ArelavHeader.vue'
import ArelavFooter from '@/views/arelav/ArelavFooter.vue'

export default {
  components: {
    FadeTransition,
    ArelavHeader,
    ArelavFooter
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

div.card {
  border-radius: 20px;
}

img.roundcorner {
  border-radius: 20px;
}

</style>
