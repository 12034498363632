





























import { Component, Vue } from 'vue-property-decorator'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'

import SignUp from '@/components/SignUp.vue'
import Verify from '@/components/Verify.vue'
import SignIn from '@/components/SignIn.vue'
import SignOut from '@/components/SignOut.vue'
import CreateDoc from '@/components/Create.vue'

@Component({
  components: {
    SignUp,
    Verify,
    SignIn,
    SignOut,
    CreateDoc
  }
})
export default class Portal extends Vue {
  user: CognitoUser | null = null
  token: string | null = null

  signedIn (newUser: CognitoUser) {
    console.log('Portal SignIn')
    this.user = newUser
    this.getToken()
  }

  signedOut () {
    this.user = null
    this.token = null
  }

  getToken () {
    if (this.user) {
      this.user.getSession(this.sessionCallback)
    } else {
      this.token = null
    }
  }

  sessionCallback (err: {}, session: CognitoUserSession | null) {
    if (err) {
      this.token = 'SESSION ERROR'
    } else if (session) {
      if (!session.isValid()) {
        this.token = 'INVALID SESSION'
      } else {
        this.token = session.getIdToken().getJwtToken()
      }
    } else {
      this.token = 'NULL SESSION'
    }
  }
}
