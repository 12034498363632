<template>
  <header
    class="header-global"
  >
    <base-nav
      class="navbar-main"
      transparent
      type=""
      effect="light"
      expand
    >
      <router-link
        slot="brand"
        class="navbar-brand mr-lg-5"
        to="/"
      >
        <img
          src="@/assets/arelav_white_small.png"
          alt="logo"
        >
      </router-link>

      <div
        class="row"
        slot="content-header"
        slot-scope="{closeMenu}"
      >
        <div class="col-6 collapse-brand">
          <a href="/">
            <img src="@/assets/arelav_blue_small.png">
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu" />
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <base-dropdown
          class="nav-item"
          menu-classes="dropdown-menu-xl"
        >
          <a
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
            role="button"
          >
            <i class="ni ni-ui-04 d-lg-none" />
            <span class="nav-link-inner--text">
              {{ content.services }}
            </span>
          </a>
          <div class="dropdown-menu-inner">
            <router-link
              v-for="(service, index) in content.cards"
              :key="index"
              :to="service.href"
            >
              <a
                class="media d-flex align-items-center"
              >
                <div :class="'icon icon-shape bg-gradient-' + service.color + ' rounded-circle text-white'">
                  <i :class="service.icon" />
                </div>
                <div class="media-body ml-3">
                  <h6 :class="'heading text-' + service.color + ' mb-md-1'">
                    {{ service.title }}
                  </h6>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{ service.subtitle }}
                  </p>
                </div>
              </a>
            </router-link>
          </div>
        </base-dropdown>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <router-link
            :to="content.url"
            v-slot="{ href }"
          >
            <a
              class="nav-link nav-link-icon"
              :href="href"
              data-toggle="tooltip"
              :title="content.contact"
            >
              <i class="fa fa-id-card" />
              {{ content.contact }}
            </a>
          </router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.facebook.com/BetterMenu-App-103698124956081/"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            title="Like us on Facebook"
          >
            <i class="fa fa-facebook-square" />
            <span class="nav-link-inner--text d-lg-none">Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="https://www.instagram.com/bettermenu.app"
            target="_blank"
            rel="noopener"
            data-toggle="tooltip"
            title="Follow us on Instagram"
          >
            <i class="fa fa-instagram" />
            <span class="nav-link-inner--text d-lg-none">Instagram</span>
          </a>
        </li>

        <base-dropdown
          tag="li"
          class="nav-item"
        >
          <a
            slot="title"
            href="#"
            class="nav-link nav-link-icon"
          >
            <i class="fa fa-globe" />
            &nbsp; Language / Idioma
          </a>
          <router-link
            to=""
            class="dropdown-item"
          >
            <a @click="setLanguage('es')">
              Español
              <i
                class="fa fa-check"
                v-if="language === 'es'"
              />
            </a>
          </router-link>
          <router-link
            to=""
            class="dropdown-item"
          >
            <a @click="setLanguage('en')">
              English
              <i
                class="fa fa-check"
                v-if="language === 'en'"
              />
            </a>
          </router-link>
        </base-dropdown>

        <li class="nav-item d-none d-lg-block ml-lg-4">
          <a
            :href="content.loginHRef"
            class="btn btn-neutral btn-icon"
          >
            <span class="btn-inner-icon">
              <i class="fa fa-inbox mr-2" />
            </span>
            <span class="nav-link-inner--text">
              {{ content.login }}
            </span>
          </a>
        </li>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import BaseNav from '@/components/BaseNav'
import BaseDropdown from '@/components/BaseDropdown'
import CloseButton from '@/components/CloseButton'
import getDefinition from '@/utils/i18n'

@Component({
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
})

export default class ArelavHeader extends Vue {
  language = ''
  languageName = 'Language'

  created () {
    this.language = this.$store.getters.language
    this.languageName = this.$store.getters.languageName
  }

  setLanguage (lang) {
    this.$store.commit('language', lang)
    this.language = this.$store.getters.language
    this.languageName = this.$store.getters.languageName
  }

  get content () {
    return getDefinition(this.$store.getters.language, 'header')
  }
}

</script>
<style>
</style>
