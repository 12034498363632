















































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

const ArelavProductHeroProps = Vue.extend({
  props: {
    section: {
      type: Object,
      required: true
    }
  }
})

@Component({
  components: {
  }
})
export default class ArelavProductHero extends mixins(ArelavProductHeroProps, Vue) {}
