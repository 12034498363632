






import { Component, Vue } from 'vue-property-decorator'
import getDefinition from '@/utils/i18n'
import ArelavPage from '@/views/arelav/ArelavPage.vue'

@Component({
  components: {
    ArelavPage
  },
  watch: {
    $route (to) {
      this.$store.commit('pageid', to.params.id)
    }
  }
})

export default class Home extends Vue {
  created () {
    this.$store.commit('pageid', this.$route.params.id)
  }

  get content () {
    return getDefinition(this.$store.getters.language, this.$store.getters.pageid)
  }
}
