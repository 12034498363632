









































































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { BCarousel } from 'bootstrap-vue/esm/components/carousel/carousel'
import { BCarouselSlide } from 'bootstrap-vue/esm/components/carousel/carousel-slide'

const ArelavHeroTopProps = Vue.extend({
  props: {
    section: {
      type: Object,
      required: true
    }
  }
})

@Component({
  components: {
    BCarousel,
    BCarouselSlide
  }
})
export default class ArelavHeroTop extends mixins(ArelavHeroTopProps, Vue) {}
