

































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { API_DOC } from '@/store/settings.ts'

const CreateDocProps = Vue.extend({
  props: {
    token: {
      type: String,
      required: true
    }
  }
})

@Component
export default class CreateDoc extends mixins(CreateDocProps, Vue) {
  notProcessing = true
  message = ''
  errormessage = ''
  filename = ''

  post () {
    console.log('File Name: ' + this.filename)
    this.notProcessing = false
    this.errormessage = ''
    this.message = ''

    return fetch(API_DOC, {
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json'
      },
      body: this.filename
    }).then(res => {
      // a non-200 response code
      if (!res.ok) {
        // create error instance with HTTP status text
        throw new Error(res.statusText)
      }
      return res.json()
    }).then(json => {
      // set the response data
      this.message = JSON.stringify(json)
    }).catch(err => {
      console.log('Error:', err)
      this.errormessage = 'Error:' + err
    }).finally(() => {
      this.notProcessing = true
    })
  }
}
