




















































































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ArelavFeatureCard from '@/components/arelav/ArelavFeatureCard.vue'

const ArelavFeaturesProps = Vue.extend({
  props: {
    section: {
      type: Object,
      required: true
    }
  }
})

@Component({
  components: {
    ArelavFeatureCard
  }
})
export default class ArelavFeatures extends mixins(ArelavFeaturesProps, Vue) {}
