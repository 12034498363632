



































import { Component, Vue } from 'vue-property-decorator'
import { Auth } from 'aws-amplify'

@Component
export default class Verify extends Vue {
  notProcessing = true
  message = ''
  username = ''
  code = ''

  verify () {
    console.log('User: ' + this.username)
    console.log('Code: ' + this.code)
    this.notProcessing = false

    Auth.confirmSignUp(
      this.username,
      this.code
    ).then(() => {
      console.log('Confirmed user')
      this.message = 'User Confirmed'
    }).catch((error) => {
      console.log('ERROR', error)
      this.message = error.message
    }).finally(() => {
      this.notProcessing = true
    })
  }

  resend () {
    console.log('User: ' + this.username)
    this.notProcessing = false

    Auth.resendSignUp(
      this.username
    ).then(() => {
      console.log('Code resent')
      this.message = 'Code resent'
    }).catch((error) => {
      console.log('ERROR', error)
      this.message = error.message
    }).finally(() => {
      this.notProcessing = true
    })
  }
}
