



















import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ArelavOfferCard from '@/components/arelav/ArelavOfferCard.vue'

const Arelav4CardsProps = Vue.extend({
  props: {
    section: {
      type: Array,
      required: true
    }
  }
})

@Component({
  components: {
    ArelavOfferCard
  }
})
export default class Arelav4Cards extends mixins(Arelav4CardsProps, Vue) {}
