<template>
  <footer class="footer has-cards">
    <div class="container container-lg">
      <div class="row">
        <div
          class="col-md-3 mb-5 mb-md-0"
          v-for="(card, index) in content.cards"
          :key="index"
        >
          <div class="card card-lift--hover shadow border-0">
            <router-link
              :to="card.destination"
              :title="card.title"
            >
              <img
                v-lazy="card.image"
                class="card-img roundcorner"
              >
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row row-grid align-items-center my-md">
        <div class="col-lg-6">
          <h3 class="text-primary font-weight-light mb-2">
            {{ content.goodbye.message }}
          </h3>
          <h4 class="mb-0 font-weight-light">
            {{ content.goodbye.detail }}
          </h4>
        </div>
        <div class="col-lg-6 text-lg-center btn-wrapper">
          <a
            rel="noopener"
            :href="content.goodbye.href"
            class="btn btn-neutral btn-github btn-round btn-lg"
            data-toggle="tooltip"
            data-original-title="Mail us"
          >
            <i class="fa fa-envelope" />
            {{ content.goodbye.button }}
          </a>
        </div>
      </div>
      <hr>
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <a
              href="mailto:info@arelav.com"
              target="_blank"
              rel="noopener"
            >Arelav.com</a>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <router-link
                :to="content.url"
              >
                <a
                  href="#"
                  class="nav-link"
                >
                  {{ content.contact }}
                </a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import getDefinition from '@/utils/i18n'

export default {
  name: 'AppFooter',
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    content: {
      get () {
        return getDefinition(this.$store.getters.language, 'footer')
      }
    }
  }
}
</script>
<style>
</style>
