import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: '',
    pageid: ''
  },
  mutations: {
    language (state, lang) {
      state.language = lang
    },
    pageid (state, id) {
      if (id && id !== '') {
        state.pageid = id
      } else {
        state.pageid = 'home'
      }
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    pageid: state => {
      return state.pageid !== '' ? state.pageid : 'home'
    },
    language: state => {
      return state.language !== '' ? state.language : navigator.language.substring(0, 2) || 'en'
    },
    languageName: state => {
      const languages = {
        en: 'English',
        es: 'Español'
      }

      return languages[state.language !== '' ? state.language : navigator.language.substring(0, 2) || 'en'] || 'Language'
    }
  }
})
