



























import { Component, Vue } from 'vue-property-decorator'
import { Auth } from 'aws-amplify'

@Component
export default class SignIn extends Vue {
  notProcessing = true
  message = ''
  username = ''
  password = ''

  signin () {
    console.log('User: ' + this.username)
    this.notProcessing = false

    Auth.signIn(
      this.username,
      this.password
    ).then((newUser) => {
      console.log('Signed user', newUser)
      this.message = 'User signed in'
      this.$emit('signedInUser', newUser)
    }).catch((error) => {
      console.log('ERROR', error)
      this.message = error.message
    }).finally(() => {
      this.notProcessing = true
    })
  }
}
