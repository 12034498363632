















import { Component, Vue } from 'vue-property-decorator'
import { Auth } from 'aws-amplify'

@Component
export default class SignOut extends Vue {
  notProcessing = true
  message = ''

  signout () {
    console.log('Signing out')
    this.notProcessing = false

    Auth.signOut().then(() => {
      console.log('Signed out')
      this.message = 'User signed out'
      this.$emit('signedOutUser')
    }).catch((error) => {
      console.log('ERROR', error)
      this.message = error.message
    }).finally(() => {
      this.notProcessing = true
    })
  }
}
