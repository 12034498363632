
const Arelav = {
  en: {
    title: 'Arelav.Com',
    subtitle: 'Business Solutions and Mobile Apps',
    detail: 'Use QR tags, NFC cards or beacons to control your business proceses, to make personalized marketing or to control accesess.',
    mail: 'mailto:ventas@arelav.com?subject=Info%20Request',
    action: 'Message Us',
    contact: 'Contact',
    url: '/page/contact'
  },
  es: {
    title: 'Arelav.Com',
    subtitle: 'Soluciones Empresariales y Aplicaciones Móviles',
    detail: 'Usa tags QR, NFC o beacons para dar seguimiento a tu procesos de negocio, controlar accesos o hacer marketing personalizado.',
    mail: 'mailto:ventas@arelav.com?subject=Solicitud%20de%Informaci%C3%B3n',
    action: 'Escríbenos',
    contact: 'Contacto',
    url: '/page/contact'

  }
}

const Tags = {
  en: {
    href: '/page/tags',
    icon: 'ni ni-tag',
    color: 'primary',
    image: '/img/services/hero_tags_small.jpg',
    heroimage: '/img/services/tags_expo_small.png',
    title: 'Tags Solutions',
    subtitle: 'Personalized Marketing, Access Control, Operations Oversight. And more solutions using QR, NFC or Beacons',
    tags: ['#QR', '#NFC', '#Beacons'],
    action: {
      title: 'Do you want to implement tags functionality in your operations',
      subtitle: 'Would you like to have a demo?',
      details: 'We can help you with the software development, with the physical tags, and even we van help you to customize them.',
      action: 'See more...'
    },
    features: [
      {
        title: 'Location based Marketing',
        details: 'Puede usar beacons para dar seguimiento a temperaturas o a otros sensores. También los puedes usar para enviar mensajes a celulares de acuerdo con su localización.'
      },
      {
        title: 'Access Logging',
        details: 'Lleva un registro de acceso o visitas a diferentes ubicaciones. Inicia distintos procesos según la tarjeta que exista en los productos o paquetes.',
        bullets: [
          'Bullet 1',
          'Bullet 2'
        ]
      },
      {
        title: 'Sensors Remote Monitoring',
        details: 'Monitor temperature, motion and other kind of sensors.'
      },
      {
        title: 'Products and Vehicles Tracking',
        details: 'Assign QR or Beacon tags to products or vehicles to start persnalized actions.',
        bullets: [
          'Bullet 3'
        ]
      }
    ]
  },
  es: {
    href: '/page/tags',
    icon: 'ni ni-tag',
    color: 'primary',
    image: '/img/services/hero_tags_small.jpg',
    heroimage: '/img/services/tags_expo_small.png',
    title: 'Servicios con Tags',
    subtitle: 'Marketing personalizado, Control de accesos y Seguimiento a Operaciones. Y más soluciones utilizando QR, Beacons o NFC.',
    tags: ['#QR', '#NFC', '#Beacons'],
    action: {
      title: '¿Quieres usar tags en tu negocio?',
      subtitle: '¿Te interesa una demo?',
      details: 'Te podemos ayudar con la solución de software, con los tags e incluso con su personalización.',
      action: 'Ver más...'
    },
    features: [
      {
        title: 'Marketing por Ubicación',
        details: 'Envía mensajes y ofertas a tus clientes según su ubicación e intereses.',
        image: '/img/services/tags_small.png',
        bullets: [
          'Identifica la ubicación e intereses de tus clientes utilizando "tags", que pueden ser QR, beacons o NFC.',
          'Envía mensajes personalizado, toma órdenes, envía ofertas o registra información de contacto.',
          'Lo puedes usar en cualquier tipo de negocio para enviar y recibir mensajes de tus clientes, o en evento de promoción en el que necesites enviar detalles y ofertas.'
        ]
      },
      {
        title: 'Seguimiento Accesos',
        details: 'Lleva un registro de acceso o visitas a diferentes ubicaciones. Inicia distintos procesos según la tarjeta que exista en los productos o paquetes.',
        image: '/img/services/tags_access_small.png',
        bullets: [

        ]
      },
      {
        title: 'Monitoreo Remoto a Sensores',
        details: 'Da seguimiento remoto a sensores de temperatura, de movimiento o de diferentes tipos'
      },
      {
        title: 'Seguimiento a Productos o Vehículos',
        details: 'Asigna tags QR o beacons a productos o vehícuos para iniciar acciones personalizadas'
      }
    ]
  }
}

const Restaurantes = {
  en: {
    href: '/page/restaurantes',
    icon: 'ni ni-map-big',
    color: 'success',
    image: '/img/services/hero_restaurante_small.jpg',
    heroimage: '/img/services/hero_restaurante_small.jpg',
    title: 'Restaurants Solutions',
    subtitle: 'QR menus solutions, online order taking, and contactless payment options.',
    tags: ['#QR', '#ContactlessPayments', '#Menus'],
    action: {
      title: 'Do yu have pictures of your menu or products?',
      subtitle: 'Start now with our free plans',
      details: 'With our online service you can configure the operation of your resturante as you need it. Additional, we can help you to design or print your QR codes or any prmotional material.',
      action: 'See more...'
    },
    features: [
      {
        title: 'QR Menu based on Pictures',
        details: 'Upload photos of your menu and publish them with a unique QR code.'
      },
      {
        title: 'Online Catalogs and Orders',
        details: 'Add all the products of   your menu or catalog and allow your customers to order online from their tables, from designated points of sales, or from anywhere.'
      },
      {
        title: 'Customer Payments and Monitoring',
        details: 'Enable contactless payments with gift cards that allows you to record and analyze your customer operations.'
      }
    ]
  },
  es: {
    href: '/page/restaurantes',
    icon: 'ni ni-map-big',
    color: 'success',
    image: '/img/services/hero_restaurante_small.jpg',
    heroimage: '/img/services/hero_restaurante_small.jpg',
    title: 'Servicios para Restaurantes',
    subtitle: 'Soluciones de Menús QR, toma de órdenes digitales y pago sin contacto.',
    tags: ['#QR', '#PagosSinContacto', '#Menús'],
    action: {
      title: '¿Tienes fotos de tu menú o de tus productos?',
      subtitle: 'Inicia ya con nuestros planes gratuitos',
      details: 'Con nuestro servicio en línea puedes configurar la operación de tu restuarante como más te convenga. Además, te podemos ayudar con el diseño e impresión de tus QR o de cualquier material que necesites.',
      action: 'Ver más...'
    },
    features: [
      {
        title: 'Menú QR basado en Fotos',
        details: 'Sube las fotos de tu menú y publícalo de inmediato con su código QR único.'
      },
      {
        title: 'Catálogo y Órdenes en línea',
        details: 'Da de alta cada producto de tu menú o catálogo y permite que tus clientes ordenen en línea desde su mesa, desde puntos de venta designados o desde cualquier lugar.'
      },
      {
        title: 'Pagos y Seguimiento a Clientes',
        details: 'Regala monederos electrónicos para que te puedan pagar sin contacto y puedas dar seguimiento y analizar las operaciones de tus clientes.'
      }
    ]
  }
}

const Processes = {
  en: {
    href: '/page/processes',
    icon: 'ni ni-ui-04',
    color: 'warning',
    image: '/img/services/hero_processes_small.jpg',
    heroimage: '/img/services/hero_processes_small.jpg',
    title: 'Digital Transformation',
    subtitle: 'Support for definition and control of enterprise and business processes (including regulatory Mexican NOM35).',
    tags: ['#Processes', '#Quality', '#QM'],
    action: {
      title: 'Do you need a simple way to align your operation?',
      subtitle: 'Would you like  Quality Control to be simpler to integrate in your services?',
      details: 'We can help you to digitize your processes to track and oversight them with evidence based quality management.',
      action: 'See more...'
    },
    features: [
      {
        title: 'Digital Transformation',
        details: 'We can help you to digitize your process so your employees and customer can work online together seamlessly.'
      },
      {
        title: 'Digital Quality Management',
        details: 'Include digitla checklists in your processes and use online reports to oversight them and to identify improvement opportunities.'
      },
      {
        title: 'Digital Surveys',
        details: 'We help you to diagnose your work environemt using digital surveys aligned with the Mexican NOM35.'
      }
    ]

  },
  es: {
    href: '/page/processes',
    icon: 'ni ni-ui-04',
    color: 'warning',
    image: '/img/services/hero_processes_small.jpg',
    heroimage: '/img/services/hero_processes_small.jpg',
    title: 'Transformación Digital',
    subtitle: 'Soluciones de apoyo a la definición y Seguimiento a procesos empresariales (incluyendo NOM35 de México).',
    tags: ['#Procesos', '#Calidad', '#NOM35'],
    action: {
      title: '¿Necesitas una forma simple de homologar tu operación?',
      subtitle: '¿Quieres que el control de calidad sea fácil de integrar en tus servicios?',
      details: 'Te podemos ayudar a digitalizar tus procesos para darles seguimiento en línea y generar evidencias de las verificaciones de calidad.',
      action: 'Ver más...'
    },
    features: [
      {
        title: 'Digitalización de Procesos',
        details: 'Te ayudamos a poner en línea tus procesos para que tus empleados y clientes trabajen de manera simple e integrada.'
      },
      {
        title: 'Administración de la Calidad',
        details: 'Integra checklists en tus procesos y genera reportes para anlizar el desempeño y mejoras de tu operación.'
      },
      {
        title: 'Evaluación de Ambiente Laboral',
        details: 'Te ayudamos a enviar encuestas digitales a tus empleados para evaluar el ambiente laboral alineado con lo requerido por la NOM35 (México).'
      }
    ]
  }
}

const Apps = {
  en: {
    href: '/page/apps',
    icon: 'ni ni-mobile-button',
    color: 'info',
    image: '/img/services/hero_apps_small.jpg',
    heroimage: '/img/services/hero_apps_small.jpg',
    title: 'Apps Development',
    subtitle: 'Custom development for business solutions and mobile apps.',
    tags: ['#iOS', '#Android', '#Development', '#BusinessIntelligence'],
    action: {
      title: 'Would you like to have your own app?',
      subtitle: 'Do you need to automate your operation?',
      details: 'We can help you from the solution design and development, to the deploy on the cloud of your applications and data.',
      action: 'See more...'
    },
    features: [
      {
        title: 'Mobile Apps',
        details: 'Let\'s build together your web app and your mobile apps for iOS and Android.'
      },
      {
        title: 'Business Intelligence',
        details: 'We can help you to design and build Data Extraction and Analysis processes, in your facilities or in the cloud.'
      },
      {
        title: 'Enterprise Solutions',
        details: 'We can help you to integrate commercial products, or to build your own tailored solution.'
      }
    ]

  },
  es: {
    href: '/page/apps',
    icon: 'ni ni-mobile-button',
    color: 'info',
    image: '/img/services/hero_apps_small.jpg',
    heroimage: '/img/services/hero_apps_small.jpg',
    title: 'Desarrollo de Apps',
    subtitle: 'Desarrollos a la medida de soluciones empresariales y aplicaciones móviles.',
    tags: ['#iOS', '#Android', '#Desarrollos', '#BusinessIntelligence'],
    action: {
      title: '¿Quieres tener tu propia app?',
      subtitle: '¿Quieres automatizar tu negocio?',
      details: 'Podemos ayudarte desde el diseño de tu aplicación. Hasta ayudarte a mover tus datos y aplicaciones a la nube.',
      action: 'Ver más...'
    },
    features: [
      {
        title: 'Apps Móviles',
        details: 'Construimos tu aplicación web y también tus aplicaciones móviles para iOS y Android.'
      },
      {
        title: 'Inteligencia de Negocio',
        details: 'Te ayudamos a generar tus procesos de Extracción y Análisis de datos en tus instalaciones o en la nube.'
      },
      {
        title: 'Soluciones Empresariales',
        details: 'Podemos ayudarte a integrar productos comerciales o a generar tu propia solución a la medida.'
      }
    ]
  }
}

const Header = {
  en: {
    url: Arelav.en.url,
    login: 'Message',
    loginHRef: Arelav.en.mail,
    contact: 'Contact',
    services: 'Services',
    cards: [
      {
        href: Tags.en.href,
        icon: Tags.en.icon,
        color: Tags.en.color,
        title: Tags.en.title,
        subtitle: Tags.en.subtitle
      },
      {
        href: Restaurantes.en.href,
        icon: Restaurantes.en.icon,
        color: Restaurantes.en.color,
        title: Restaurantes.en.title,
        subtitle: Restaurantes.en.subtitle
      },
      {
        href: Processes.en.href,
        icon: Processes.en.icon,
        color: Processes.en.color,
        title: Processes.en.title,
        subtitle: Processes.en.subtitle
      },
      {
        href: Apps.en.href,
        icon: Apps.en.icon,
        color: Apps.en.color,
        title: Apps.en.title,
        subtitle: Apps.en.subtitle
      }
    ]
  },
  es: {
    url: Arelav.es.url,
    login: 'Mensaje',
    loginHRef: Arelav.es.mail,
    contact: 'Contacto',
    services: 'Servicios',
    cards: [
      {
        href: Tags.es.href,
        icon: Tags.es.icon,
        color: Tags.es.color,
        title: Tags.es.title,
        subtitle: Tags.es.subtitle
      },
      {
        href: Restaurantes.es.href,
        icon: Restaurantes.es.icon,
        color: Restaurantes.es.color,
        title: Restaurantes.es.title,
        subtitle: Restaurantes.es.subtitle
      },
      {
        href: Processes.es.href,
        icon: Processes.es.icon,
        color: Processes.es.color,
        title: Processes.es.title,
        subtitle: Processes.es.subtitle
      },
      {
        href: Apps.es.href,
        icon: Apps.es.icon,
        color: Apps.es.color,
        title: Apps.es.title,
        subtitle: Apps.es.subtitle
      }
    ]
  }
}

const Footer = {
  en: {
    contact: 'Contact Data',
    url: Arelav.en.url,
    goodbye: {
      message: 'Thank you for your interest',
      detail: 'Please contact us if you want to know pricing and additional details.',
      href: Arelav.en.mail,
      button: 'Ask for details'

    },
    cards: [
      {
        destination: Tags.en.href,
        title: Tags.en.title,
        image: Tags.en.image
      },
      {
        destination: Restaurantes.en.href,
        title: Restaurantes.en.title,
        image: Restaurantes.en.image
      },
      {
        destination: Processes.en.href,
        title: Processes.en.title,
        image: Processes.en.image
      },
      {
        destination: Apps.en.href,
        title: Apps.en.title,
        image: Apps.en.image
      }
    ]
  },
  es: {
    contact: 'Datos de Contacto',
    url: Arelav.es.url,
    goodbye: {
      message: 'Gracias por tu interés',
      detail: 'Contáctanos para conocer precios y más detalles.',
      href: Arelav.es.mail,
      button: 'Más información'

    },
    cards: [
      {
        destination: Tags.es.href,
        title: Tags.es.title,
        image: Tags.es.image
      },
      {
        destination: Restaurantes.es.href,
        title: Restaurantes.es.title,
        image: Restaurantes.es.image
      },
      {
        destination: Processes.es.href,
        title: Processes.es.title,
        image: Processes.es.image
      },
      {
        destination: Apps.es.href,
        title: Apps.es.title,
        image: Apps.es.image
      }
    ]
  }
}

const HomePage = {
  en: {
    sections: [
      {
        type: 'HEROTOP',
        hero: {
          title: Arelav.en.title,
          subtitle: Arelav.en.subtitle,
          detail: Arelav.en.detail,
          action: {
            title: Arelav.en.action,
            destination: Arelav.en.mail
          },
          info: {
            title: Arelav.en.contact,
            destination: Arelav.en.url
          },
          carousel: [
            Tags.en.image,
            Restaurantes.en.image,
            Processes.en.image,
            Apps.en.image
          ]
        }
      },
      {
        type: '4CARDS',
        cards: [
          {
            icon: Tags.en.icon,
            style: Tags.en.color,
            title: Tags.en.title,
            details: Tags.en.subtitle,
            tags: Tags.en.tags,
            action: 'More ...',
            destination: Tags.en.href
          },
          {
            icon: Restaurantes.en.icon,
            style: Restaurantes.en.color,
            title: Restaurantes.en.title,
            details: Restaurantes.en.subtitle,
            tags: Restaurantes.en.tags,
            action: 'More ...',
            destination: Restaurantes.en.href
          },
          {
            icon: Processes.en.icon,
            style: Processes.en.color,
            title: Processes.en.title,
            details: Processes.en.subtitle,
            tags: Processes.en.tags,
            action: 'More ...',
            destination: Processes.en.href
          },
          {
            icon: Apps.en.icon,
            style: Apps.en.color,
            title: Apps.en.title,
            details: Apps.en.subtitle,
            tags: Apps.en.tags,
            action: 'More ...',
            destination: Apps.en.href
          }
        ]
      },
      {
        type: 'FEATURES',
        features: {
          title: Tags.en.title,
          details: Tags.en.subtitle,
          style: Tags.en.color,
          icon: Tags.en.icon,
          image: Tags.en.heroimage,
          action: Tags.en.action,
          href: Tags.en.href,
          cards: Tags.en.features
        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Restaurantes.en.title,
          details: Restaurantes.en.subtitle,
          style: Restaurantes.en.color,
          icon: Restaurantes.en.icon,
          image: Restaurantes.en.heroimage,
          action: Restaurantes.en.action,
          href: Restaurantes.en.href,
          cards: Restaurantes.en.features
        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Processes.en.title,
          details: Processes.en.subtitle,
          style: Processes.en.color,
          icon: Processes.en.icon,
          image: Processes.en.heroimage,
          action: Processes.en.action,
          href: Processes.en.href,
          cards: Processes.en.features

        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Apps.en.title,
          details: Apps.en.subtitle,
          style: Apps.en.color,
          icon: Apps.en.icon,
          image: Apps.en.heroimage,
          action: Apps.en.action,
          href: Apps.en.href,
          cards: Apps.en.features
        }
      }
    ]
  },
  es: {
    sections: [
      {
        type: 'HEROTOP',
        hero: {
          title: Arelav.es.title,
          subtitle: Arelav.es.subtitle,
          detail: Arelav.es.detail,
          action: {
            title: Arelav.es.action,
            destination: Arelav.es.mail
          },
          info: {
            title: Arelav.es.contact,
            destination: Arelav.es.url
          },
          carousel: [
            Tags.es.image,
            Restaurantes.es.image,
            Processes.es.image,
            Apps.es.image
          ]
        }
      },
      {
        type: '4CARDS',
        cards: [
          {
            icon: Tags.es.icon,
            style: Tags.es.color,
            title: Tags.es.title,
            details: Tags.es.subtitle,
            tags: Tags.es.tags,
            action: 'Más ...',
            destination: Tags.es.href
          },
          {
            icon: Restaurantes.es.icon,
            style: Restaurantes.es.color,
            title: Restaurantes.es.title,
            details: Restaurantes.es.subtitle,
            tags: Restaurantes.es.tags,
            action: 'Más ...',
            destination: Restaurantes.es.href
          },
          {
            icon: Processes.es.icon,
            style: Processes.es.color,
            title: Processes.es.title,
            details: Processes.es.subtitle,
            tags: Processes.es.tags,
            action: 'Más ...',
            destination: Processes.es.href
          },
          {
            icon: Apps.es.icon,
            style: Apps.es.color,
            title: Apps.es.title,
            details: Apps.es.subtitle,
            tags: Apps.es.tags,
            action: 'Más ...',
            destination: Apps.es.href
          }
        ]
      },
      {
        type: 'FEATURES',
        features: {
          title: Tags.es.title,
          details: Tags.es.subtitle,
          style: Tags.es.color,
          icon: Tags.es.icon,
          image: Tags.es.heroimage,
          action: Tags.es.action,
          href: Tags.es.href,
          cards: Tags.es.features
        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Restaurantes.es.title,
          details: Restaurantes.es.subtitle,
          style: Restaurantes.es.color,
          icon: Restaurantes.es.icon,
          image: Restaurantes.es.heroimage,
          action: Restaurantes.es.action,
          href: Restaurantes.es.href,
          cards: Restaurantes.es.features
        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Processes.es.title,
          details: Processes.es.subtitle,
          style: Processes.es.color,
          icon: Processes.es.icon,
          image: Processes.es.heroimage,
          action: Processes.es.action,
          href: Processes.es.href,
          cards: Processes.es.features

        }
      },
      {
        type: 'FEATURES',
        features: {
          title: Apps.es.title,
          details: Apps.es.subtitle,
          style: Apps.es.color,
          icon: Apps.es.icon,
          image: Apps.es.heroimage,
          action: Apps.es.action,
          href: Apps.es.href,
          cards: Apps.es.features
        }
      }
    ]
  }
}

function productPage (obj) {
  return {
    en: {
      sections: [
        {
          type: 'PRODUCT',
          product: {
            title: obj.en.title,
            details: obj.en.subtitle,
            style: obj.en.color,
            icon: obj.en.icon,
            image: obj.en.heroimage
          }
        },
        {
          type: 'SECTIONS',
          icon: obj.en.icon,
          style: obj.en.color,
          image: obj.en.image,
          sections: obj.en.features
        }
      ]
    },
    es: {
      sections: [
        {
          type: 'PRODUCT',
          product: {
            title: obj.es.title,
            details: obj.es.subtitle,
            style: obj.es.color,
            icon: obj.es.icon,
            image: obj.es.heroimage
          }
        },
        {
          type: 'SECTIONS',
          icon: obj.es.icon,
          style: obj.es.color,
          image: obj.es.image,
          sections: obj.es.features
        }
      ]
    }
  }
}

function contactPage (obj) {
  return {
    en: {
      sections: [
        {
          type: 'HEROTOP',
          hero: {
            title: obj.en.title,
            subtitle: obj.en.subtitle,
            detail: obj.en.detail,
            action: {
              title: obj.en.action,
              destination: obj.en.mail
            },
            info: {
              title: obj.en.contact,
              destination: Arelav.en.url
            },
            carousel: [
            ]
          }
        }
      ]
    },
    es: {
      sections: [
        {
          type: 'HEROTOP',
          hero: {
            title: obj.es.title,
            subtitle: obj.es.subtitle,
            detail: obj.es.detail,
            action: {
              title: obj.es.action,
              destination: obj.es.mail
            },
            info: {
              title: obj.es.contact,
              destination: Arelav.es.url
            },
            carousel: [
            ]
          }
        }
      ]
    }
  }
}

const content = {
  header: Header,
  footer: Footer,
  home: HomePage,
  tags: productPage(Tags),
  restaurantes: productPage(Restaurantes),
  processes: productPage(Processes),
  apps: productPage(Apps),
  contact: contactPage(Arelav)
}

function getDefinition (lang: string, page: string) {
  return content[page][lang]
}

export default getDefinition
