

































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ArelavHeroTop from '@/components/arelav/ArelavHeroTop.vue'
import Arelav4Cards from '@/components/arelav/Arelav4Cards.vue'
import ArelavFeatures from '@/components/arelav/ArelavFeatures.vue'
import ArelavProductHero from '@/components/arelav/ArelavProductHero.vue'
import ArelavSection from '@/components/arelav/ArelavSection.vue'

const ArelavPageProps = Vue.extend({
  props: {
    content: {
      type: Object,
      required: true
    }
  }
})

@Component({
  components: {
    ArelavHeroTop,
    Arelav4Cards,
    ArelavFeatures,
    ArelavProductHero,
    ArelavSection
  }
})
export default class ArelavPage extends mixins(ArelavPageProps, Vue) {}
