import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Arelav from '../views/arelav/Arelav.vue'
import Contact from '../views/Contact.vue'
import Home from '../views/Home.vue'
import Portal from '../views/Portal.vue'

import Components from '../views/Components.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/page/:id',
    name: 'Arelav',
    component: Arelav
  },
  {
    path: '/',
    name: 'Home',
    component: Arelav
  },

  {
    path: '/tmp',
    name: 'Portal',
    component: Portal
  },
  {
    path: '/tmp/contact',
    name: 'ContactTemp',
    component: Contact
  },
  {
    path: '/tmp/vue',
    name: 'VueHome',
    component: Home
  },
  {
    path: '/tmp/components',
    name: 'Components',
    component: Components
  },
  {
    path: '/tmp/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (/* to, from, savedPosition */) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
