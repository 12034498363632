

























































/* eslint quote-props: ["error", "consistent-as-needed", { "keywords": true }] */
import { Component, Vue } from 'vue-property-decorator'
import { Auth } from 'aws-amplify'

@Component
export default class SignUp extends Vue {
  notProcessing = true
  message = ''
  username = ''
  password = ''
  name = ''
  middle_name = ''
  given_name = ''
  phone = ''
  services = ''

  signup () {
    console.log('User: ' + this.username)
    this.notProcessing = false

    Auth.signUp({
      username: this.username,
      password: this.password,
      attributes: {
        'name': this.name,
        'middle_name': this.middle_name,
        'given_name': this.given_name,
        'phone_number': this.phone,
        'custom:ServiciosInteres': this.services
      }
    }).then((newUser) => {
      console.log('New user', newUser)
      this.message = 'User created'
    }).catch((error) => {
      console.log('ERROR', error)
      this.message = error.message
    }).finally(() => {
      this.notProcessing = true
    })
  }
}
